const appConfig = {
  apiPrefix: "https://sandbox.trabo.ai",
  googleAnalyticsMesurementId: null,
  paypalPlanID: "P-0RA010870N413754KMYQO7LI",
  authenticatedEntryPathUser: "/market",
  authenticatedEntryPathAdmin: "/instances",
  userUnAuthenticatedEntryPath: "/sign-in",
  adminUnAuthenticatedEntryPath: "/admin",
  tourPath: "/",
  enableMock: false,
  dataController: "Trabo Company",
};

export default appConfig;
